* {
  font-family: "Pro Racing";
  color: white;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
  overflow-x: hidden;
}

@font-face {
  font-family: "Pro Racing";
  src: url("./pro_racing.otf") format("opentype");
}

@font-face {
  font-family: "Mont";
  src: url("./mont.ttf") format("truetype");
}

/* .nav-btn {
  border-radius: 10px;
  color: #FEFCD5;
  background-color: black;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 45px;
  max-width: 160px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  transition-property: all;
  transition-duration: .6s;
  transition-timing-function: ease;
  border: 0 solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
  outline: 1px solid;
  outline-color: rgba(255, 255, 255, .5);
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    text-decoration: none;
  }
}

.nav-btn:hover {
  border: 1px solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
} */