.login-page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-txt {
    width: 100%;
    text-align: center;
    height: 10%;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-cont {
    border-radius: 10px;
    border: 2px solid white;
    width: 500px;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        height: 30%;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 60%;

        input {
            margin-top: 10px;
            width: 90%;
            outline: none;
            background-color: black;
            color: white;
            padding: 5px;
            border: none;
            border-bottom: 2px solid white;
            font-size: 20px;
            font-family: "Mont";
        }

        button {
            margin-top: 10px;
            width: 50%;
            height: 40px;
            background-color: black;
            outline: none;
            border: 2px solid white;
            font-size: 20px;
            padding: 10px;
            border-radius: 15px;
            cursor: pointer;
        }

        button:hover {
            background-color: white;
            color: black;
        }

        p {
            font-size: 18px;
            text-align: center;
        }

        span {
            color: blue;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.back-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    border: 2px solid white;
    border-radius: 100px;
    height: 40px;
    width: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.back-btn:hover {
    color: black;
    background-color: white;
}

@media screen and (max-width: 1100px) {
    .login-page {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .login-txt {
        font-size: 35px;
    }
    
    .login-cont {
        border-radius: 10px;
        border: 2px solid white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 75%;
        margin-top: 5%;
    
        img {
            height: 30%;
        }
    
        form {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            height: 60%;
    
            input {
                margin-top: 10px;
                width: 90%;
                outline: none;
                background-color: black;
                color: white;
                padding: 5px;
                border: none;
                border-bottom: 2px solid white;
                font-size: 20px;
                font-family: "Mont";
            }
    
            button {
                margin-top: 10px;
                width: 50%;
                height: 40px;
                background-color: black;
                outline: none;
                border: 2px solid white;
                font-size: 20px;
                padding: 10px;
                border-radius: 15px;
                cursor: pointer;
            }
    
            button:hover {
                background-color: white;
                color: black;
            }
    
            p {
                font-size: 18px;
                text-align: center;
            }
    
            span {
                color: blue;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
    
    .back-btn {
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
        border: 2px solid white;
        border-radius: 100px;
        height: 40px;
        width: 40px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
    }
    
    .back-btn:hover {
        color: black;
        background-color: white;
    }
}