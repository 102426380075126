.profile-page {
    width: 100%;
    height: 100vh;
}

.profile-header {
    position: absolute;
    left: 0;
    top: 200px;
    font-size: 25px;
    margin-left: 10%;
    width: 90%;
    height: 10%;
    display: flex;
    align-items: center;
}

.profile-body {
    position: absolute;
    left: 0;
    top: calc(200px + 10%);
    width: 100%;
    height: calc(100% - 200px - 10%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-content {
    width: 80%;
    height: 90%;
    border: 2px solid white;
    box-sizing: border-box;
    border-radius: 25px;
    overflow: hidden;
}

.profile-categories {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid white;
    box-sizing: border-box;
}

.profile-category1 {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
}

.profile-category1.active {
    background-color: white;

    h3 {
        color: black;
    }
}

.profile-category1:hover {
    background-color: white;

    h3 {
        color: black;
    }
}

.profile-category2 {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px solid white;
    border-left: 2px solid white;
    cursor: pointer;
    font-size: 20px;
}

.profile-category2.active {
    background-color: white;

    h3 {
        color: black;
    }
}

.profile-category2:hover {
    background-color: white;

    h3 {
        color: black;
    }
}

.profile-category3 {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
}

.profile-category3.active {
    background-color: white;

    h3 {
        color: black;
    }
}

.profile-category3:hover {
    background-color: white;

    h3 {
        color: black;
    }
}

.profile-views {
    position: relative;
    width: 100%;
    height: 90%;
}

.profile-details-cont {
    width: 90%;
    min-height: 100%;
    max-height: 100%;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    button {
        width: 30%;
        margin-left: 35%;
        margin-right: 35%;
        font-size: 20px;
        padding: 10px;
        outline: none;
        border-radius: 10px;
        border: 2px solid white;
        background-color: black;
        cursor: pointer;
        color: white;
    }

    button:hover {
        background-color: white;
        color: black;
    }
}

.details-form {
    width: 90%;
    height: 100%;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.det-inp-cont {
    width: 100%;
    display: flex;
    align-items: center;

    input {
        width: 60%;
        padding: 5px;
        outline: none;
        border-radius: 10px;
        border: 2px solid white;
        background-color: black;
        color: white;
        font-size: 20px;
        margin-right: 5%;
        margin-left: auto;
    }
}

.det-btn-cont {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    button {
        width: 30%;
        font-size: 20px;
        padding: 10px;
        outline: none;
        border-radius: 10px;
        border: 2px solid white;
        background-color: black;
        cursor: pointer;
        color: white;
    }

    button:hover {
        background-color: white;
        color: black;
    }
}

.bookings-cont {
    width: 95%;
    height: 100%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    overflow-x: hidden;
    overflow-y: auto;
}

.bookings-cont.no-apts {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bookings-cont::-webkit-scrollbar {
    display: none;
}

.booking-card {
    width: 100%;
    height: 10%;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0% 2.5%;
    border-radius: 15px;
    box-sizing: border-box;
    border: 2px solid white;
    color: white;
    cursor: pointer;
}

.booking-card:hover {
    background-color: white;

    * {
        color: black;
    }
}

.gallery-cont {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
}

.gallery-cont.no-images {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-cont::-webkit-scrollbar {
    display: none;
}

.gallery-card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 2px solid white;
        box-sizing: border-box;
        border-radius: 15px;
        cursor: pointer;
    }

    p {
        position: absolute;
        opacity: 0;
        font-size: 25px;
        -webkit-text-stroke: 1px black;
    }
}

.gallery-card:hover {

    img {
        filter: blur(3px);
    }

    p {
        opacity: 1;
    }
}

@media screen and (max-width: 1100px) {
    .profile-page {
        width: 100%;
        height: 100vh;
    }

    .profile-header {
        position: absolute;
        left: 0;
        top: 150px;
        font-size: 18px;
        margin-left: 10%;
        width: 80%;
        height: 10%;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .profile-body {
        position: absolute;
        left: 0;
        top: calc(200px + 10%);
        width: 100%;
        height: calc(100% - 200px - 10%);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile-content {
        width: 95%;
        height: 90%;
        border: 2px solid white;
        box-sizing: border-box;
        border-radius: 25px;
        overflow: hidden;
    }

    .profile-categories {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-bottom: 2px solid white;
        box-sizing: border-box;
    }

    .profile-category1 {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 12px;
    }

    .profile-category1.active {
        background-color: white;

        h3 {
            color: black;
        }
    }

    .profile-category1:hover {
        background-color: white;

        h3 {
            color: black;
        }
    }

    .profile-category2 {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid white;
        border-left: 2px solid white;
        cursor: pointer;
        font-size: 12px;
    }

    .profile-category2.active {
        background-color: white;

        h3 {
            color: black;
        }
    }

    .profile-category2:hover {
        background-color: white;

        h3 {
            color: black;
        }
    }

    .profile-category3 {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 12px;
    }

    .profile-category3.active {
        background-color: white;

        h3 {
            color: black;
        }
    }

    .profile-category3:hover {
        background-color: white;

        h3 {
            color: black;
        }
    }

    .profile-views {
        position: relative;
        width: 100%;
        height: 90%;
    }

    .profile-details-cont {
        width: 90%;
        min-height: 100%;
        max-height: 100%;
        padding-left: 5%;
        padding-right: 5%;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        button {
            width: 80%;
            margin-left: 10%;
            margin-right: 10%;
            font-size: 15px;
            padding: 10px;
            outline: none;
            border-radius: 10px;
            border: 2px solid white;
            background-color: black;
            cursor: pointer;
            color: white;
        }

        button:hover {
            background-color: white;
            color: black;
        }
    }

    .details-form {
        width: 90%;
        height: 100%;
        padding-left: 5%;
        padding-right: 5%;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .det-inp-cont {
        width: 100%;
        display: flex;
        align-items: center;

        input {
            width: 60%;
            padding: 2px;
            outline: none;
            border-radius: 10px;
            border: 2px solid white;
            background-color: black;
            color: white;
            font-size: 15px;
            margin-right: 5%;
            margin-left: auto;
        }
    }

    .det-btn-cont {
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        button {
            width: 40%;
            font-size: 15px;
            padding: 10px;
            outline: none;
            border-radius: 10px;
            border: 2px solid white;
            background-color: black;
            cursor: pointer;
            color: white;
        }

        button:hover {
            background-color: white;
            color: black;
        }
    }

    .bookings-cont {
        width: 95%;
        height: 100%;
        margin-left: 2.5%;
        margin-right: 2.5%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .bookings-cont.no-apts {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bookings-cont::-webkit-scrollbar {
        display: none;
    }

    .booking-card {
        width: 100%;
        height: 30%;
        margin-top: 20px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 0% 2.5%;
        border-radius: 15px;
        box-sizing: border-box;
        border: 2px solid white;
        color: white;
        cursor: pointer;
    }

    .booking-card:hover {
        background-color: white;

        * {
            color: black;
        }
    }

    .gallery-cont {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
    }

    .gallery-cont.no-images {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .gallery-cont::-webkit-scrollbar {
        display: none;
    }

    .gallery-card {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border: 2px solid white;
            box-sizing: border-box;
            border-radius: 15px;
            cursor: pointer;
        }

        p {
            position: absolute;
            opacity: 0;
            font-size: 15px;
            -webkit-text-stroke: 1px black;
        }
    }

    .gallery-card:hover {

        img {
            filter: blur(3px);
        }

        p {
            opacity: 1;
        }
    }
}