.admin-page {
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
        cursor: pointer;
        background-color: black;
        outline: none;
        padding: 10px;
        border-radius: 10px;
        border: 2px solid white;
    }

    button:hover {
        background-color: white;
        color: black;
    }

    button:disabled {
        opacity: 0.5;
    }
}

.ad-users-cont {
    width: 40%;
    height: 100%;
}

.ad-users-header {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.ad-users {
    height: 70%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5% 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    box-sizing: border-box;
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid white;
}

.ad-user {
    margin-top: 10px;
    width: 90%;
}

.ad-user-btns {
    width: 90%;
    margin-top: 20px;
}

.ad-users-btns {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid white;
    box-sizing: border-box;
}

.ad-apts-cont {
    width: 40%;
    height: 100%;
}

.ad-apts-header {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: rebeccapu
}

.ad-apts {
    height: 70%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5% 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    box-sizing: border-box;
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid white;
}

.ad-apt {
    width: 90%;
}

.ad-apt-btns {
    width: 90%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    button {
        width: 45%;
    }
}

.ad-apts-btns {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid white;
    box-sizing: border-box;
}

.admin-login {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 40%;

    button {
        cursor: pointer;
        background-color: black;
        outline: none;
        padding: 10px;
        border-radius: 10px;
        border: 2px solid white;
        font-size: 20px;
    }

    button:hover {
        background-color: white;
        color: black;
    }
}

@media screen and (max-width: 1100px) {
    .admin-page {
        width: 100%;
        height: 210vh;
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 10vh;
    }

    .ad-users-cont {
        width: 95%;
        height: 100vh;
        border-bottom: 2px solid white;
    }

    .ad-apts-cont {
        width: 95%;
        height: 100vh;
        border-bottom: 2px solid white;
    }

    .admin-login {
        width: 95%;
    }

    .ad-users-header {
        font-size: 20px;
    }

    .ad-apts-header {
        font-size: 15px;
    }
}


