.uo-cont {
    font-family: "Pro Racing";
}


.nav-btn {
    background-color: black;
    font-family: "Pro Racing";
    border: 2px solid white;
    border-radius: 10px;
    padding: 15px 20px;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
}

.nav-btn:hover {
    background-color: white;
    color: black;
}

.nav-cont {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100px;
    display: flex;
    background-color: black;
    z-index: 99;
}

.nav-ls {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 40%;
    height: 100%;
    border-bottom: 2px solid white;
    box-sizing: border-box;
}

.nav-ct {
    width: 20%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    height: 100%;
    width: auto;
}

.nav-rs {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid white;
    box-sizing: border-box;
}

.landing-pg {
    position: absolute;
    top: 200px;
    width: 100%;
    height: calc(100vh - 200px);
}

.slogan-cont {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;

    h1 {
        text-align: center;
    }
}

.services-cont {
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.service {
    width: 20%;
    height: 70%;
    border: 2px solid white;
    border-radius: 15px;
    overflow: hidden;
}

.service-title {
    width: 100%;
    height: 10%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    text-align: center;
}

.service-img {
    width: 100%;
    height: 50%;
    border-bottom: 2px solid white;
    border-top: 2px solid white;
    box-sizing: border-box;
}

.service-txt {
    padding: 15px 15px;
    margin: 0;
    height: calc(40% - 30px);
    width: calc(100% - 30px);
    text-align: center;
    overflow-x: hidden;
    overflow-y: auto;
}

.service-txt::-webkit-scrollbar {
    display: none;
}

.packages-pg {
    height: calc(100vh - 250px);
    padding-top: 200px;
    position: absolute;
    left: 0;
    top: calc(100vh + 200px);
    width: 100%;
}

.package-header-cont {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex-direction: column;

    a {
        margin-top: 10px;
    }
}

.package-header {}

.packages-cont {
    height: 45%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.package {
    position: relative;
    width: 20%;
    height: 90%;
    border: 2px solid;
    border-radius: 15px;
    overflow: hidden;
    box-sizing: border-box;
}

.package-title {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    border-bottom: 2px solid;
    box-sizing: border-box;
}

.package-txt {
    width: 90%;
    margin: 5%;
    height: 85%;
    font-size: 20px;
    overflow-y: auto;
    overflow-x: hidden;
}

.package-txt::-webkit-scrollbar {
    display: none;
}

.before-after-cont {
    height: 45%;
}

.ba-header {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.ba-header-txt {}

.ba-cont {
    height: 100%;
    width: 100%;
    display: flex;
}

.ba-ls {
    height: 100%;
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.video-cont {
    height: 90%;
    width: 50%;
    border-radius: 15px;
    overflow: hidden;
    box-sizing: border-box;
    border: 2px solid white;
}

.details-cont {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.ba-rs {
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.apointment-choices {
    height: 80%;
    width: 40%;
}

.choices-row {
    height: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.choice {
    height: 95%;
    width: 45%;
    border-radius: 15px;
    overflow: hidden;
    box-sizing: border-box;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.choice:hover {
    border: 2px solid black;
}

.book-btn {
    font-family: "Pro Racing";
    background-color: black;
    font-family: "Pro Racing";
    border: 2px solid white;
    border-radius: 10px;
    font-size: 25px;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 70px;
    width: 130px;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    z-index: 90;
    background-color: black;
}

.book-btn:hover {
    background-color: white;
    color: black;
}

.book-btn:disabled {
    opacity: 0.5;
}

.contact-pg {
    height: calc(100vh - 200px);
    margin-top: 100px;
    position: absolute;
    left: 0;
    top: calc(200vh + 200px);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact-cont {
    height: 85%;
    width: 100%;
    display: flex;
}

.contact-ls {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-header {
    font-size: 35px;
    margin-bottom: 20px;
    text-align: center;
}

.auth-form {
    width: 70%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 15px;
    overflow: hidden;
    box-sizing: border-box;
    border: 2px solid white;

    label {
        font-size: 25px;
        width: 100%;
        text-align: center;
    }

    input {
        margin-top: 15px;
        font-size: 20px;
        width: 80%;
        height: 30px;
        margin-bottom: 60px;
        border-radius: 15px;
        overflow: hidden;
        box-sizing: border-box;
        border: 2px solid white;
        background-color: black;
        color: white;
        font-family: "Pro Racing";
        padding: 5px;
    }

    button {
        margin-top: -20px;
        margin-bottom: 40px;
        font-size: 20px;
        padding: 10px;
        outline: none;
        border-radius: 10px;
        border: 2px solid white;
        background-color: black;
        cursor: pointer;
    }

    button:hover {
        background-color: white;
        color: black;
    }

    p {
        font-size: 20px;
        text-align: center;
    }

    span {
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }
}

.contact-rs {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.mission-header {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
}

.mission-cont {
    height: 75%;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: 2px solid white;
    border-radius: 15px;
    overflow: hidden;
    box-sizing: border-box;

    p {
        width: 95%;
        height: 95%;
        padding: 2.5%;
        font-size: 26px;
        overflow-y: auto;
        overflow-x: hidden;
        font-family: "Mont";
    }

    p::-webkit-scrollbar {
        display: none;
    }
}

.account-opt {
    height: 15%;
    width: 70%;
    object-fit: cover;
}

.footer {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-txt {
    font-size: 35px;
    text-align: center;
    margin-left: 10%;
    width: 80%;
    margin-right: 10%;
}

.booking-cont {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.booking-window {
    width: 50%;
    height: 70%;
    margin-top: 200px;
    border-radius: 10px;
    border: 2px solid white;
    overflow: hidden;
    background-color: black;
    box-shadow: inset 0 0 30px rgba(255, 255, 255, 0.5);
}

.booking-form {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    label {
        font-size: 15px;
        width: 90%;
        text-align: left;
        margin-left: 4%;
        padding-top: 20px;
        padding-bottom: 10px;
    }

    input {
        font-size: 15px;
        width: 90%;
        height: 30px;
        margin-left: 5%;
        margin-right: 10px;
        border-radius: 15px;
        overflow: hidden;
        box-sizing: border-box;
        border: 2px solid white;
        background-color: black;
        color: white;
        font-family: "Pro Racing";
        padding-left: 10px;
        color-scheme: dark;
        padding-right: 10px;
    }

    select {
        outline: none;
        font-size: 15px;
        width: 90%;
        height: 30px;
        margin-left: 5%;
        margin-right: 10px;
        border-radius: 15px;
        overflow: hidden;
        box-sizing: border-box;
        border: 2px solid white;
        background-color: black;
        color: white;
        font-family: "Pro Racing";
        padding-left: 10px;
        padding-right: 10px;
    }

    textarea {
        margin-left: 5%;
        width: 90%;
        height: 100px;
        font-size: 15px;
        border-radius: 15px;
        overflow: hidden;
        box-sizing: border-box;
        border: 2px solid white;
        background-color: black;
        color: white;
        font-family: "Pro Racing";
        padding-left: 10px;
        margin-bottom: 50px;
        padding-right: 10px;
        resize: none;
        overflow-y: auto;
    }
}


.time-choices {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    row-gap: 10px;

    .time {
        margin: 5px;
        width: calc(100% - 30px);
        padding: 10px;
        text-align: center;
        border-radius: 15px;
        border: 2px solid white;
        box-sizing: border-box;
        cursor: pointer;
    }

    .time:hover {
        background-color: white;
        color: black;
    }

    .time.sel {
        background-color: white;
        color: black;
    }
}

.booking-header {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 2px solid white;
}

.booking-body {
    width: 100%;
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
}

.form-btns {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;

    button {
        width: 35%;
        font-size: 20px;
        background-color: black;
        outline: none;
        border-radius: 15px;
        border: 2px solid white;
        padding: 10px;
        cursor: pointer;
    }

    button:hover {
        background-color: white;
        color: black;
    }
}

.loader-cont {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    font-size: 30px;
    -webkit-text-stroke: 1px black;
}

.mobile-nav {
    display: none;
}

.mobile-nav.active {
    display: none;
}

@media screen and (max-width: 1100px) {
    .uo-cont {
        font-family: "Pro Racing";
    }


    .nav-btn {
        background-color: black;
        font-family: "Pro Racing";
        border: 2px solid white;
        border-radius: 10px;
        padding: 15px 20px;
        font-size: 20px;
        cursor: pointer;
        text-decoration: none;
    }

    .nav-btn:hover {
        background-color: white;
        color: black;
    }

    .nav-cont {
        display: none;
    }

    .nav-ls {
        display: none;
    }

    .nav-ct {
        display: none;
    }

    .nav-rs {
        display: none;
    }

    .landing-pg {
        position: absolute;
        top: 100px;
        width: 100%;
        height: calc(285vh - 200px);
    }

    .slogan-cont {
        width: 100%;
        height: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;

        h1 {
            text-align: center;
        }
    }

    .services-cont {
        height: 95%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .service {
        width: 90%;
        height: 30%;
        border: 2px solid white;
        border-radius: 15px;
        overflow: hidden;
    }

    .service-title {
        width: 100%;
        height: 10%;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        text-align: center;
    }

    .service-img {
        width: 100%;
        height: 50%;
        border-bottom: 2px solid white;
        border-top: 2px solid white;
        box-sizing: border-box;
        object-fit: cover;
    }

    .service-txt {
        padding: 15px 15px;
        margin: 0;
        height: calc(40% - 30px);
        width: calc(100% - 30px);
        text-align: center;
        overflow-x: hidden;
        overflow-y: auto;
        font-size: 20px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .service-txt::-webkit-scrollbar {
        display: none;
    }

    .packages-pg {
        height: calc(400vh - 50px);
        padding-top: 100px;
        position: absolute;
        left: 0;
        top: calc(300vh + 150px);
        width: 100%;
    }

    .package-header-cont {
        height: 10%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        flex-direction: column;

        a {
            margin-top: 30px;
            padding: 10px;
            background-color: black;
            border-radius: 15px;
            border: 2px solid white;
            text-decoration: none;
        }

        a:hover {
            background-color: white;
            color: black;
        }
    }

    .package-header {
        text-align: center;
    }

    .packages-cont {
        height: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }

    .package {
        position: relative;
        width: 90%;
        height: 23%;
        border: 2px solid;
        border-radius: 15px;
        overflow: hidden;
        box-sizing: border-box;
    }

    .package-title {
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        border-bottom: 2px solid;
        box-sizing: border-box;
    }

    .package-txt {
        width: 90%;
        margin: 5%;
        height: 85%;
        font-size: 20px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .package-txt::-webkit-scrollbar {
        display: none;
    }

    .before-after-cont {
        height: 40%;
    }

    .ba-header {
        height: 15%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }

    .ba-header-txt {
        font-size: 30px;
    }

    .ba-cont {
        height: 85%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .ba-ls {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .video-cont {
        display: none;
    }

    .details-cont {
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        span {
            display: none;
        }
    }

    .ba-rs {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        display: flex;
        flex-direction: column;

        .det-lg {
            font-size: 30px !important;
            text-align: center;
            margin-bottom: 20px;
            margin-top: 20px;
        }

        .det-sm {
            font-size: 18px !important;
            text-align: center;
        }
    }

    .apointment-choices {
        height: 100%;
        width: 100%;
    }

    .choices-row {
        height: 50%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .choice {
        height: 95%;
        width: 45%;
        border-radius: 15px;
        overflow: hidden;
        box-sizing: border-box;
        border: 2px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .choice:hover {
        border: 2px solid white;
    }

    .book-btn {
        font-family: "Pro Racing";
        background-color: black;
        font-family: "Pro Racing";
        border: 2px solid white;
        border-radius: 10px;
        padding: 15px 20px;
        font-size: 20px;
        cursor: pointer;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        position: fixed;
        bottom: 20px;
        left: 0px;
        height: 25px;
    }

    .book-btn:hover {
        background-color: white;
        color: black;
    }

    .contact-pg {
        height: calc(250vh - 200px);
        padding-top: 100px;
        position: absolute;
        left: 0;
        top: calc(580vh);
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .contact-cont {
        height: 92%;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .contact-ls {
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-header {
        font-size: 35px;
        margin-bottom: 20px;
        text-align: center;
    }

    .auth-form {
        width: 90%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 15px;
        overflow: hidden;
        box-sizing: border-box;
        border: 2px solid white;

        label {
            font-size: 15px !important;
            width: 95%;
            text-align: center;
        }

        input {
            margin-top: 15px;
            font-size: 20px;
            width: 90%;
            height: 30px;
            margin-bottom: 60px;
            border-radius: 15px;
            overflow: hidden;
            box-sizing: border-box;
            border: 2px solid white;
            background-color: black;
            color: white;
            font-family: "Pro Racing";
            padding: 5px;
        }

        button {
            margin-top: -20px;
            margin-bottom: 40px;
            font-size: 20px;
            padding: 10px;
            outline: none;
            border-radius: 10px;
            border: 2px solid white;
            background-color: black;
            cursor: pointer;
        }

        button:hover {
            background-color: white;
            color: black;
        }

        p {
            font-size: 20px;
            text-align: center;
            width: 95%;
        }

        span {
            color: blue;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .contact-rs {
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .mission-header {
        height: 10%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 35px;
    }

    .mission-cont {
        height: 90%;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border: 2px solid white;
        border-radius: 15px;
        overflow: hidden;
        box-sizing: border-box;

        p {
            width: 95%;
            height: 95%;
            padding: 2.5%;
            font-size: 24px;
            overflow-y: auto;
            overflow-x: hidden;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        p::-webkit-scrollbar {
            display: none;
        }
    }

    .account-opt {
        height: 15%;
        width: 70%;
        object-fit: cover;
    }

    .footer {
        height: 5%;
        padding-bottom: 200px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer-txt {
        font-size: 30px;
        text-align: center;
    }

    .booking-cont {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
    }

    .booking-window {
        width: 90%;
        height: 60%;
        margin-top: 50px;
        border-radius: 10px;
        border: 2px solid white;
        overflow: hidden;
        background-color: black;
        box-shadow: inset 0 0 30px rgba(255, 255, 255, 0.5);
    }

    .booking-form {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        position: relative;

        label {
            font-size: 15px;
            width: 90%;
            text-align: left;
            margin-left: 4%;
            padding-top: 20px;
            padding-bottom: 10px;
        }

        input {
            font-size: 15px;
            width: 90%;
            height: 30px;
            margin-left: 5%;
            margin-right: 10px;
            border-radius: 15px;
            overflow: hidden;
            box-sizing: border-box;
            border: 2px solid white;
            background-color: black;
            color: white;
            font-family: "Pro Racing";
            padding-left: 10px;
            color-scheme: dark;
            padding-right: 10px;
        }

        select {
            outline: none;
            font-size: 15px;
            width: 90%;
            height: 30px;
            margin-left: 5%;
            margin-right: 10px;
            border-radius: 15px;
            overflow: hidden;
            box-sizing: border-box;
            border: 2px solid white;
            background-color: black;
            color: white;
            font-family: "Pro Racing";
            padding-left: 10px;
            padding-right: 10px;
        }

        textarea {
            margin-left: 5%;
            width: 90%;
            height: 100px;
            font-size: 15px;
            border-radius: 15px;
            overflow: hidden;
            box-sizing: border-box;
            border: 2px solid white;
            background-color: black;
            color: white;
            font-family: "Pro Racing";
            padding-left: 10px;
            margin-bottom: 50px;
            padding-right: 10px;
            resize: none;
            overflow-y: auto;
        }
    }

    .time-choices {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        row-gap: 10px;

        p {
            margin: 5px;
            width: calc(100% - 30px);
            padding: 10px;
            text-align: center;
            border-radius: 15px;
            border: 2px solid white;
            box-sizing: border-box;
            cursor: pointer;
        }

        p:hover {
            background-color: white;
            color: black;
        }
    }


    .booking-header {
        height: 10%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 2px solid white;
        font-size: 9px;
    }

    .booking-body {
        width: 100%;
        height: 90%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .form-btns {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 20px;

        button {
            width: 40%;
            font-size: 15px;
            background-color: black;
            outline: none;
            border-radius: 15px;
            border: 2px solid white;
            padding: 10px;
            cursor: pointer;
        }

        button:hover {
            background-color: white;
            color: black;
        }
    }

    .loader-cont {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        font-size: 30px;
        -webkit-text-stroke: 1px black;
    }

    .mobile-nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: black;
        z-index: 99;
        display: block;
        border-bottom: 2px solid white;
        transition: height 0.5s;
        overflow: hidden;
    }

    .mobile-nav.active {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 80vh;
        background-color: black;
        z-index: 99;
        display: block;
        border-bottom: 2px solid white;
        transition: height 0.5s;
    }

    .m-nav-ct {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
    }

    .m-logo {
        height: 100px;
        width: 100px;
    }

    .m-icn {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 35px;
        height: 35px;
        cursor: pointer;
    }

    .m-nav-opt {
        height: calc((80vh - 100px)/4);
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            height: 50%;
            width: 100%;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0;
            font-size: 25px;
        }

        a:hover {
            background-color: white;
            color: black;
        }
    }
}