.package-details-page {
    width: 100%;
    height: 100vh;
    position: absolute;
    height: calc(100vh - 200px);
    padding-top: 200px;
    left: 0;
    top: calc(100vh);
    width: 100%;
}

.packages-details-title {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

.package-details-div {
    position: relative;
    width: 24%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.package-details-cont {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-around;


    h2 {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
    }

    .value-pack {
        position: relative;
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
            position: absolute;
            width: 100px;
            height: 100px;
            right: 0px;
        }

        h2 {
            width: 100%;
            height: 100%;
        }
    }

    .package-info {
        position: relative;
        width: calc(100% - 40px);
        height: calc(90% - 40px);
        padding: 20px;
        margin-bottom: 10%;
        border: 2px solid white;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 15px;
        font-family: "Arial";
        font-size: max(1vw, 16px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
            width: 100%;
            font-family: "Arial";
        }

        p {
            width: 100%;
            font-family: "Arial";
        }
    }

    .package-info::-webkit-scrollbar {
        display: none;
    }


}

.package-footer {
    height: 10%;
    width: (100% - 20px);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    padding: 0px 10px;

    p {
        font-size: 22px;
        font-family: "Arial";
    }
}

.back-btn {
    position: fixed;
    left: 25px;
    top: 25px;

    h1 {
        padding: 10px;
        font-size: 20px;
        border: 2px solid white;
        border-radius: 15px;
        cursor: pointer;
    }

    h1:hover {
        background-color: white;
        color: black;
    }
}

@media screen and (max-width: 1100px) {
    .package-details-page {
        position: absolute;
        top: calc(250vh + 100px);
        width: 100%;
        height: 300vh;
    }

    .packages-details-title {
        width: 100%;
        height: 4%;
        margin-bottom: 1%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        text-align: center;
        box-sizing: border-box;
    }

    .package-details-div {
        width: 90%;
        height: 25%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .package-details-cont {
        width: 100%;
        height: 85%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;

        h2 {
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            text-align: center;
        }

        .value-pack {
            position: relative;
            width: 100%;
            height: 10%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            img {
                position: absolute;
                width: 100px;
                height: 100px;
                right: 0px;
            }

            h2 {
                width: 100%;
                height: 100%;
            }
        }

        .package-info {
            position: relative !important;
            width: calc(100% - 20px) !important;
            height: calc(90% - 20px) !important;
            padding: 10px !important;
            margin-bottom: 10% !important;
            border: 2px solid white !important;
            box-sizing: border-box !important;
            overflow-y: auto !important;
            overflow-x: hidden !important;
            border-radius: 15px !important;
            font-family: "Arial" !important;
            font-size: 2vh !important;

            h3 {
                width: 100%;
                font-family: "Arial";
                text-align: center;
            }

            p {
                width: 100%;
                font-family: "Arial";
            }
        }

        .package-info::-webkit-scrollbar {
            display: none;
        }


    }

    .best-value {
        width: 10vh !important;
        height: 10vh !important;
    }

    .package-footer {
        height: 10%;
        width: (100% - 20px);
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        font-size: 20px;
        padding: 0px 10px;

        h2 {
            text-align: center;
        }

        p {
            font-size: 22px;
            font-family: "Arial";
            text-align: center;
        }
    }

    .back-btn {
        position: fixed;
        left: 5px;
        top: 5px;

        h1 {
            padding: 5px;
            font-size: 15px;
            border: 2px solid white;
            border-radius: 15px;
            cursor: pointer;
            width: 10px;
            height: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        h1:hover {
            background-color: white;
            color: black;
        }
    }
}